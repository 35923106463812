<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-row class="row">
          <TableTitleComponent title="在库报表"/>
          <StockArticleReport/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="入库数据报表"/>
          <DataWarehousingReport/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="耗用数据报表"/>
          <ConsumptionDataReport/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="维修数据报表"/>
          <MaintenanceDataReport/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="报废数据报表"/>
          <ScrapDataReport/>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import StockArticleReport from "@/views/recyclableBox/StockArticleReport";
  import DataWarehousingReport from "@/views/recyclableBox/DataWarehousingReport";
  import TableTitleComponent from "@/components/TableTitleComponent";
  import ConsumptionDataReport from "@/views/recyclableBox/ConsumptionDataReport";
  import MaintenanceDataReport from "@/views/recyclableBox/MaintenanceDataReport";
  import ScrapDataReport from "@/views/recyclableBox/ScrapDataReport";

export default {
  name: "RecyclableBoxExport",
  components:{
    StockArticleReport, DataWarehousingReport, TableTitleComponent, ConsumptionDataReport, MaintenanceDataReport, ScrapDataReport
  }
}
</script>

<style scoped>
  .row {
    padding-bottom: 20px;
  }
</style>